import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import useLocalStorage from '../shared/useLocalStorage';
import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from '../assets/jss/styles/components/dashboardStyle';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import Slide from '@material-ui/core/Slide';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormControlLabel,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { create } from 'apisauce';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import RangeSlider from '../components/RangeSlider';
import YearSlider from '../components/YearSlider';
import FilterText from '../components/FilterText';
import projectStyles, { useStyles } from '../styles/projects';
import { useTranslation } from 'react-i18next';
import ProjectsSearch from '../components/ProjectsSearch.jsx';
import SDGImageFilter from '../components/SDG/SDGImageFilter';
import getHeaderLInks from '../shared/headerLinks.js';
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Brush,
  PieChart,
  Pie,
  Cell,
  ComposedChart,
  LabelList,
  Label,
  ReferenceLine,
  LineChart,
  Line,
} from 'recharts';
import { Container } from '@material-ui/core';
import { ThreeDots } from 'react-loader-spinner';
import InvestmentBarChartCustomShape from '../components/Charts/InvestmentBarChartCustomShape.jsx';
import SectorsPieChartWithPaddingAngle from '../components/Charts/SectorsPieChartWithPaddingAngle.jsx';
import ProjectCountByCountry from '../components/Charts/ProjectCountByCountry.jsx';

const apiSauceInstance = create({ baseURL: Constants.apiUrl });

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const {
  buttonSubmit,
  buttonClear,
  buttonApply,
  FilterHeading,
  Filter,
  MobileFilter,
  filterItem,
  costRangeInput,
  costRangeInputMobile,
  clearSection,

  TopGrid,
  SectorGridTop,
  HeadingTop,
  statisticsContainer,
  linearcss,
  legendscss,
  fontStyle,
  noContentsStyle,
  ChartsTopGrid,
} = projectStyles;

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((acc, param) => {
          let [keyValue, ...values] = param.split('::');
          let [key, value] = keyValue.split('=');
          if (value) {
            const paramValues = [value, ...values];
            acc[key] = (paramValues || []).map(value =>
              decodeURIComponent(value.replace(/\+/g, ' '))
            );
            return acc;
          }
          acc[key] = undefined;
          return [];
        }, {})
    : {};
};

const Projects = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const helmetLink = `${Constants.appUrl}/projects`;
  const { location, navigate } = props;

  const programLink = pageContext.programUrl;

  const headerLinks = getHeaderLInks(
    'Analytics',
    programLink,
    data.noLinks,
    ''
  );

  const logos = pageContext.logos || [];

  const { pageHeader, headerLogo, headerLogoScroll, countryData, screenName } =
    (data || {}).node || {};
  const industrialParks =
    (
      (
        (((data || {}).node || {}).industrialParks || {}).childMarkdownRemark ||
        {}
      ).frontmatter || {}
    ).industrialParksData || [];
  const partners =
    (
      ((((data || {}).node || {}).indexData || {}).childMarkdownRemark || {})
        .frontmatter || {}
    ).partners || [];

  industrialParks.forEach(sez => {
    sez.isSEZ = true;
  });

  partners.forEach(partner => {
    partner.isPartner = true;
  });

  const styleClasses = useStyles();
  const [filteredProjects] = useState([]);
  const [searchCriteria] = useState(
    getQueryStringParams(location.search || '')
  );
  const theme = useTheme();
  const [costRange, setCostRange] = useState([0, 0]);
  const [countries, setCountries] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [investmentTypes, setInvestmentTypes] = useState([]);
  const [sdg, setSdg] = useState([]);
  const [source, setSource] = useState([]);
  const [yearRange, setYearRange] = useState([0, 0]);
  const [openDialog, setOpenDialog] = useState(false);
  const [filterTab, setFilterTab] = useState(0);
  const [isProcessing, setIsProcessing] = useState(true);
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const [filterMasterData, setFilterMasterData] = useLocalStorage(
  //   `${data.node.programName ? data.node.programName : ''}filterMasterData`,
  //   null,
  //   24
  // );
  const [filterMasterData, setFilterMasterData] = useState([]);
  const [, setPageNumber] = useState(1);
  const [projectsCount] = useState(0);
  const [isCostRangeUpdated, setCostRangeUpdated] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [clearFilterSection, setClearFilterSection] = useState(false);
  const [filterSectionExpand, setFilterSectionExpand] = useState('');
  const [isYearRangeUpdated, setYearRangeUpdated] = useState(false);

  //Apply Button functionality
  const [sourceApplied, setSourceApplied] = useState(false);
  const [countryApplied, setCountryApplied] = useState(false);
  const [sectorApplied, setSectorApplied] = useState(false);
  const [investmentTypeApplied, setInvestmentTypeApplied] = useState(false);
  const [sdgApplied, setSdgApplied] = useState(false);
  const [totalInvestmentCostApplied, setTotalInvestmentCostApplied] = useState(
    false
  );
  const [yearRangeApplied, setYearRangeApplied] = useState(false);
  const [minYear, setMinYear] = useState(0);
  const [maxCurrentYear, setMaxcurrentyear] = useState(0);

  useEffect(() => {
    setIsProcessing(true);
    apiSauceInstance
      .get(
        Constants.getFilterMaster +
          `${data.node.programName ? '/' + data.node.programName : ''}` +
          `/${i18n.language}`
      )
      .then(filterCriteria => {
        if (filterCriteria && filterCriteria.data) {
          if (filterCriteria.data.SDG) {
            filterCriteria.data.SDG.sort((a, b) => a.sdgid - b.sdgid);
          }

          if (filterCriteria.data.TOTALINVESTMENTCOST) {
            setCostRange([
              filterCriteria.data.TOTALINVESTMENTCOST.min,
              filterCriteria.data.TOTALINVESTMENTCOST.max,
            ]);
          }

          setFilterMasterData({ ...filterCriteria.data });
        } else if (filterCriteria && !filterCriteria.ok) {
          setIsProcessing(false);
        }
      })
      .catch(error => {
        if (error) {
          setIsProcessing(false);
        }
      });

    if (filterMasterData.TOTALINVESTMENTCOST) {
      setCostRange([
        filterMasterData.TOTALINVESTMENTCOST.min,
        filterMasterData.TOTALINVESTMENTCOST.max,
      ]);
    }
  }, [i18n.language]);

  useEffect(() => {
    setMinYear(1980);
    const today = new Date(),
      currentyear = today.getFullYear();
    setMaxcurrentyear(currentyear);
    setYearRange([1980, currentyear]);
  }, []);

  useEffect(() => {
    setFilterValues(searchCriteria);
  }, []);

  useEffect(() => {
    if (clearFilterSection) {
      setClearFilterSection(false);
      filterClick('filter')();
    }
  }, [clearFilterSection]);

  const filterClick = (action, inputQuery = null) => () => {
    let params = {};
    let queryString = '';
    if (action === 'filter' || action === 'clearSearch') {
      params = {
        search: searchString && action === 'filter' ? searchString : '',
        Source: source ? source : [],
        Country: countries ? countries : [],
        Sector: sectors ? sectors : [],
        InvestmentType: investmentTypes ? investmentTypes : [],
        sdg: sdg ? sdg : [],
        TotalInvestmentCost: isCostRangeUpdated ? costRange.map(String) : null,
      };
      Object.keys(params).forEach(key => {
        if (!params[key] || params[key].length === 0) {
          delete params[key];
        }
      });

      queryString =
        '?' +
        Object.keys(params)
          .map(key =>
            key === 'search'
              ? `${key}=${params[key]}`
              : `${key}=${params[key].reduce((acc, value) => {
                  return acc === '' ? `${acc}${value}` : `${acc}::${value}`;
                }, '')}`
          )
          .join('&');
      setOpenDialog(false);
    } else {
      setCostRange([
        0,
        ((filterMasterData || {}).TOTALINVESTMENTCOST || {}).max || 999999,
      ]);
      setCountries([]);
      setSectors([]);
      setInvestmentTypes([]);
      setSdg([]);
      setCostRangeUpdated(false);
      setSource([]);
      setSearchString('');
      setPageNumber(1);
      setYearRange([minYear, maxCurrentYear]);
    }
    setPageNumber(1);
    navigate(`${location.pathname}${inputQuery || queryString}`, {});
  };

  const setFilterValues = params => {
    const filterMap = {
      Country: setCountries,
      TotalInvestmentCost: setCostRange,
      Sector: setSectors,
      updated: () => {},
      InvestmentType: setInvestmentTypes,
      sdg: setSdg,
      search: setSearchString,
      Source: setSource,
    };

    if (Object.keys(params).find(key => key === 'Favorites')) {
      //setisFavoritesView(true);
      return;
    } else {
      //setisFavoritesView(false);
    }

    Object.keys(params).map(key => {
      const setFilter = filterMap[key] || (() => {});
      key === 'search'
        ? setFilter((params[key] || [''])[0])
        : setFilter(params[key].filter(e => e != '').map(val => parseInt(val)));
    });
  };

  const updateStateElement = (
    stateCollection,
    updateState,
    updateChange
  ) => value => () => {
    const collection = stateCollection || [];
    const currentIndex = (collection || []).indexOf(value);
    const updatedState =
      currentIndex === -1
        ? [...collection, value]
        : collection
            .slice(0, currentIndex)
            .concat(collection.slice(currentIndex + 1));
    updateState(updatedState);
    updateChange(true);
  };

  const handleCountriesChange = updateStateElement(
    countries,
    setCountries,
    setCountryApplied
  );
  const handleSectorsChange = updateStateElement(
    sectors,
    setSectors,
    setSectorApplied
  );
  const handleInvestmentTypesChange = updateStateElement(
    investmentTypes,
    setInvestmentTypes,
    setInvestmentTypeApplied
  );
  const handleSDGChange = updateStateElement(sdg, setSdg, setSdgApplied);
  const handleSourceChange = updateStateElement(
    source,
    setSource,
    setSourceApplied
  );
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleFilterTabChange = (event, newValue) => {
    setFilterTab(newValue);
  };

  const updateCostRange = (event, value) => {
    setCostRangeUpdated(true);
    setCostRange(value);
    setTotalInvestmentCostApplied(true);
  };

  const updateYearRange = (event, value) => {
    setYearRangeUpdated(true);
    setYearRange(value);
    setYearRangeApplied(true);
  };

  // Infinite Scroll Functionality

  // Search Functionality
  const onProjectsSearch = action => {
    action
      ? searchString.length === 0
        ? location.href &&
          location.href.indexOf('?') > 0 &&
          filterClick('clearSearch')()
        : searchString.length > 2 && filterClick('filter')()
      : location.href &&
        location.href.indexOf('?') > 0 &&
        filterClick('clearSearch')();
  };

  // Filter Expansion panel

  const handleExpansionClick = panel => (event, isExpanded) => {
    setFilterSectionExpand(isExpanded ? panel : false);
  };

  const renderCustomizedLabel = props => {
    /* eslint-disable */
    const { content, ...rest } = props;
    /* eslint-enable */
    return (
      <Label
        {...rest}
        fontSize="12"
        fill="#FFFFFF"
        fontWeight="Bold"
        position="end"
      />
    );
  };

  const [projectschartCount, setchartProjectsCount] = useState(0);
  const [investmentTypeData, setInvestmentTypeData] = useState();
  const [sectorData, setSectorData] = useState();
  const [countryID] = useState();
  const isLoading = false;

  useEffect(() => {
    getStatisticsDetails();
  }, []);

  function getStatisticsDetails() {
    apiSauceInstance
      .get(Constants.getStatisticsDetails + `/${data.node.programName}`)
      .then(({ data, status }) => {
        if (status !== 400) {
          setchartProjectsCount(data.projectCount);
          if (data.projectsBySector) {
            setSectorData(data.projectsBySector.sectors);
          }
          if (data.projectsByInvestmentType) {
            setInvestmentTypeData(
              data.projectsByInvestmentType.investmentTypes
            );
          }
        }
      })
      .catch(error => {
        if (error) {
          // do something
        }
      });
  }

  const COLORS = ['#0088FE', '#FFBB28', '#00C49F', '#9131eb', '#FF8042'];

  const ipaCountryData = [
    { name: 'Cameroon', quality_ratio: 1.45 },
    { name: 'Ethiopia', quality_ratio: 1.08 },
    { name: 'Mozambique', quality_ratio: 1.07 },
    { name: 'Kenya', quality_ratio: 1.6 },
    { name: 'Senegal', quality_ratio: 1.01 },
    { name: 'Tanzania', quality_ratio: 1.0 },
    { name: 'Burkina Faso', quality_ratio: 1.6 },
    { name: 'Nigeria', quality_ratio: 0.99 },
    { name: 'Malawi', quality_ratio: 0.99 },
    { name: 'Ghana', quality_ratio: 0.98 },
    { name: 'Lesotho', quality_ratio: 0.97 },
    { name: 'Cape Verde', quality_ratio: 0.96 },
    { name: 'Uganda', quality_ratio: 0.96 },
    { name: 'Rwanda', quality_ratio: 0.91 },
    { name: 'Zambia', quality_ratio: 0.87 },
    { name: 'Madagascar', quality_ratio: 0.85 },
    { name: 'Burundi', quality_ratio: 0.79 },
    { name: 'Niger', quality_ratio: 0.73 },
    { name: 'Mali', quality_ratio: 0.71 },
  ];

  const surveyCountriesData = [
    {
      name: 'France',
      existing_Investor: 49,
      external_advisor: 9,
      diaspora: 12,
      parentCompanyChannel: 5,
      embassy: 5,
      directContactWithIPA: 3,
      privateSectorMediaAndOther: 18,
    },
    {
      name: 'India',
      existing_Investor: 53,
      external_advisor: 10,
      diaspora: 3,
      parentCompanyChannel: 7,
      embassy: 5,
      directContactWithIPA: 5,
      privateSectorMediaAndOther: 17,
    },
    {
      name: 'Latin_America',
      existing_Investor: 54,
      external_advisor: 23,
      diaspora: 48,
      embassy: 9,
      directContactWithIPA: 3,
      privateSectorMediaAndOther: 21,
    },
    {
      name: 'MENA',
      existing_Investor: 48,
      external_advisor: 9,
      diaspora: 8,
      parentCompanyChannel: 4,
      embassy: 8,
      directContactWithIPA: 6,
      privateSectorMediaAndOther: 16,
    },
    {
      name: 'Asia_Oceania',
      existing_Investor: 42,
      external_advisor: 10,
      diaspora: 10,
      parentCompanyChannel: 4,
      embassy: 6,
      directContactWithIPA: 6,
      privateSectorMediaAndOther: 21,
    },
    {
      name: 'Other_Europe',
      existing_Investor: 43,
      external_advisor: 7,
      diaspora: 10,
      parentCompanyChannel: 7,
      embassy: 6,
      directContactWithIPA: 3,
      privateSectorMediaAndOther: 24,
    },
    {
      name: 'SSA',
      existing_Investor: 42,
      external_advisor: 8,
      diaspora: 8,
      parentCompanyChannel: 10,
      embassy: 3,
      directContactWithIPA: 6,
      privateSectorMediaAndOther: 23,
    },
  ];

  const pieChartData = [
    { name: 'Burundi', value: 182 },
    { name: 'Burkina Faso', value: 123 },
    { name: 'Cameroon', value: 270 },
    { name: 'Cape Verde', value: 406 },
    { name: 'Cote dIvorie', value: 78 },
    { name: 'Cote dIvorie', value: 78 },
    { name: 'Ethiopia', value: 568 },
    { name: 'Ghana', value: 417 },
    { name: 'Kenya', value: 875 },
    { name: 'Lesotho', value: 179 },
    { name: 'Madagascar', value: 235 },
    { name: 'Malawi', value: 93 },
    { name: 'Mali', value: 276 },
    { name: 'Mozambique', value: 272 },
    { name: 'Niger', value: 106 },
    { name: 'Nigena', value: 579 },
    { name: 'Rawanda', value: 169 },
    { name: 'Senegal', value: 296 },
    { name: 'Tanzania', value: 373 },
    { name: 'Uganda', value: 854 },
    { name: 'Zambia', value: 326 },
  ];

  const investorData = [
    { name: 'Burkina Faso', Importance: -3.3, Quality: 3.32 },
    { name: 'Cameroon', Importance: -3.38, Quality: 2.33 },
    { name: 'Ethiopia', Importance: -3.43, Quality: 3.18 },
    { name: 'Ghana', Importance: -3.38, Quality: 3.46 },
    { name: 'Kenya', Importance: -3.4, Quality: 3.22 },
    { name: 'Lesotho', Importance: 3.57, Quality: -3.46 },
    { name: 'Malawi', Importance: -3.64, Quality: 3.69 },
    { name: 'Mozambique', Importance: -3.78, Quality: 3.45 },
    { name: 'Niger', Importance: -3.21, Quality: 4.38 },
    { name: 'Nigeria', Importance: -3.5, Quality: 3.54 },
    { name: 'Uganda', Importance: -3.38, Quality: 3.52 },
    { name: 'Zambia', Importance: -3.25, Quality: 3.73 },
  ];

  const investorUpdateData = [
    { name: 'Burkina', Importance: 3.3, Quality: 3.32 },
    { name: 'Cameroon', Importance: 3.38, Quality: 2.33 },
    { name: 'Ethiopia', Importance: 3.43, Quality: 3.18 },
    { name: 'Ghana', Importance: 3.38, Quality: 3.46 },
    { name: 'Kenya', Importance: 3.4, Quality: 3.22 },
    { name: 'Lesotho', Importance: 3.57, Quality: 3.46 },
    { name: 'Malawi', Importance: 3.64, Quality: 3.69 },
    { name: 'Mozambique', Importance: 3.78, Quality: 3.45 },
    { name: 'Niger', Importance: 3.21, Quality: 4.38 },
    { name: 'Nigeria', Importance: 3.5, Quality: 3.54 },
    { name: 'Uganda', Importance: 3.38, Quality: 3.52 },
    { name: 'Zambia', Importance: 3.25, Quality: 3.73 },
  ];

  const linearData = [
    { name: 'Jan', projectCount: 40 },
    { name: 'Feb', projectCount: 70 },
    { name: 'Mar', projectCount: 110 },
    { name: 'Apr', projectCount: 100 },
    { name: 'May', projectCount: 170 },
    { name: 'Jun', projectCount: 70 },
    { name: 'Jul', projectCount: 90 },
    { name: 'Aug', projectCount: 120 },
    { name: 'Sep', projectCount: 60 },
    { name: 'Oct', projectCount: 30 },
    { name: 'Nov', projectCount: 140 },
    { name: 'Dec', projectCount: 90 },
  ];

  return (
    <Layout
      programLink={programLink}
      title={t('analytics_title')}
      content={[t('analytics_subtitle')]}
      imageName={'bg-analytics.png'}
      contentAlignmentStyle={'textCenter'}
      headerLinks={headerLinks}
      logos={logos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'analytics' }}
      screenName={screenName}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Investment Promotion Portal Projects')}</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>

      <div className="container">
        <GridContainer className="projects-container">
          <GridItem md={4}>
            <ProjectsSearch
              onProjectsSearch={onProjectsSearch}
              searchString={searchString}
              setSearchString={setSearchString}
              placeholder={t('search_statistics')}
            />
            {!mobileScreen ? (
              <>
                <div className="filters-container">
                  <ExpansionPanel
                    expanded={filterSectionExpand === 'source'}
                    onChange={handleExpansionClick('source')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={styleClasses.heading}>
                        {t('source')}
                        {source.length ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setSource([]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {sourceApplied ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                            updateElement={setSourceApplied}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={styleClasses.contents}>
                      <List>
                        {filterMasterData &&
                          (filterMasterData.SOURCE || []).map(value => {
                            return (
                              <ListItem key={value.campaignSourceID}>
                                <Checkbox
                                  color="primary"
                                  className={styleClasses.checkbox}
                                  edge="start"
                                  checked={
                                    source.indexOf(value.campaignSourceID) !==
                                    -1
                                  }
                                  onChange={handleSourceChange(
                                    value.campaignSourceID
                                  )}
                                  tabIndex={-1}
                                  disableRipple
                                  value={value.campaignSourceID}
                                ></Checkbox>
                                <p css={filterItem}>
                                  {value.campaignSourceName}
                                </p>
                              </ListItem>
                            );
                          })}
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  {!(countryData || {}).title && (
                    <ExpansionPanel
                      expanded={filterSectionExpand === 'country'}
                      onChange={handleExpansionClick('country')}
                      className={styleClasses.panel}
                    >
                      <ExpansionPanelSummary
                        className={styleClasses.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={styleClasses.heading}>
                          {t('countries')}
                          {countries.length ? (
                            <ClearFilterSection
                              handleClick={() => {
                                setCountries([]);
                                setClearFilterSection(true);
                              }}
                            />
                          ) : (
                            ''
                          )}
                          {countryApplied ? (
                            <ApplyFilterSection
                              handleClick={filterClick('filter')}
                              updateElement={setCountryApplied}
                            />
                          ) : (
                            ''
                          )}
                        </Typography>
                      </ExpansionPanelSummary>

                      <ExpansionPanelDetails className={styleClasses.contents}>
                        <List>
                          {filterMasterData &&
                            (filterMasterData.COUNTRY || []).map(value => {
                              return (
                                <ListItem key={value.countryID}>
                                  <Checkbox
                                    color="primary"
                                    className={styleClasses.checkbox}
                                    edge="start"
                                    checked={
                                      countries.indexOf(value.countryID) !== -1
                                    }
                                    onChange={handleCountriesChange(
                                      value.countryID
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    value={value.countryID}
                                  ></Checkbox>
                                  <p css={filterItem}>{value.countryName}</p>
                                </ListItem>
                              );
                            })}
                        </List>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )}
                  <ExpansionPanel
                    expanded={filterSectionExpand === 'sectors'}
                    onChange={handleExpansionClick('sectors')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={styleClasses.heading}>
                        {t('sectors')}
                        {sectors.length ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setSectors([]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {sectorApplied ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                            updateElement={setSectorApplied}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={styleClasses.contents}>
                      <List>
                        {filterMasterData &&
                          (filterMasterData.SECTOR || []).map(value => {
                            return (
                              <ListItem key={value.companySectorCodeID}>
                                <Checkbox
                                  color="primary"
                                  className={styleClasses.checkbox}
                                  edge="start"
                                  checked={
                                    source.indexOf(
                                      value.companySectorCodeID
                                    ) !== -1
                                  }
                                  onChange={handleSectorsChange(
                                    value.companySectorCodeID
                                  )}
                                  tabIndex={-1}
                                  disableRipple
                                  value={value.companySectorCodeID}
                                ></Checkbox>
                                <p css={filterItem}>{value.description}</p>
                              </ListItem>
                            );
                          })}
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel
                    expanded={filterSectionExpand === 'investmentTypes'}
                    onChange={handleExpansionClick('investmentTypes')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={styleClasses.heading}>
                        {t('investment_type')}
                        {investmentTypes.length ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setInvestmentTypes([]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {investmentTypeApplied ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                            updateElement={setInvestmentTypeApplied}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={styleClasses.contents}>
                      <List>
                        {filterMasterData &&
                          (filterMasterData.PROJECTOPPORTUNITYTYPE || []).map(
                            value => {
                              return (
                                <ListItem key={value.projectOpportunityTypeID}>
                                  <Checkbox
                                    color="primary"
                                    className={styleClasses.checkbox}
                                    edge="start"
                                    checked={
                                      source.indexOf(
                                        value.projectOpportunityTypeID
                                      ) !== -1
                                    }
                                    onChange={handleSectorsChange(
                                      value.projectOpportunityTypeID
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    value={value.projectOpportunityTypeID}
                                  ></Checkbox>
                                  <p css={filterItem}>
                                    {value.projectOpportunityTypeName}
                                  </p>
                                </ListItem>
                              );
                            }
                          )}
                      </List>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel
                    expanded={filterSectionExpand === 'sdg'}
                    onChange={handleExpansionClick('sdg')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={styleClasses.heading}>
                        {t('SDG')}
                        {sdg.length ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setSdg([]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}

                        {sdgApplied ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                            updateElement={setSdgApplied}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={styleClasses.contents}>
                      <span>
                        {filterMasterData && filterMasterData.SDG && (
                          <SDGImageFilter
                            SDGData={filterMasterData.SDG}
                            sdg={sdg}
                            handleSDGChange={handleSDGChange}
                          />
                        )}
                      </span>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel
                    expanded={filterSectionExpand === 'totalInvestmentCost'}
                    onChange={handleExpansionClick('totalInvestmentCost')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={styleClasses.heading}>
                        {t('Total Investment Cost')}
                        {isCostRangeUpdated ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setCostRangeUpdated(false);
                              setCostRange([
                                0,
                                filterMasterData.TOTALINVESTMENTCOST.max ||
                                  999999,
                              ]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {totalInvestmentCostApplied ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                            updateElement={setTotalInvestmentCostApplied}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      className={styleClasses.costContents}
                    >
                      <div>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          css={costRangeInput}
                        >
                          <InputLabel htmlFor="CostMin">Min</InputLabel>
                          <OutlinedInput
                            id="CostMin"
                            value={costRange[0].toLocaleString()}
                            onFocus={e => e.target.select()}
                            onChange={e => {
                              setCostRangeUpdated(true);
                              setTotalInvestmentCostApplied(true);
                              setCostRange([
                                parseInt(e.target.value.replace(/\D/g, '')) ||
                                  0,
                                costRange[1],
                              ]);
                            }}
                            endAdornment={
                              <InputAdornment position="end">$</InputAdornment>
                            }
                            labelWidth={30}
                          />
                        </FormControl>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          css={costRangeInput}
                        >
                          <InputLabel htmlFor="CostMax">Max</InputLabel>
                          <OutlinedInput
                            id="CostMax"
                            value={costRange[1].toLocaleString()}
                            onFocus={e => e.target.select()}
                            onChange={e => {
                              setCostRangeUpdated(true);
                              setTotalInvestmentCostApplied(true);
                              setCostRange([
                                costRange[0],
                                parseInt(e.target.value.replace(/\D/g, '')) ||
                                  0,
                              ]);
                            }}
                            endAdornment={
                              <InputAdornment position="end">$</InputAdornment>
                            }
                            labelWidth={30}
                          />
                        </FormControl>
                      </div>

                      {filterMasterData &&
                      filterMasterData.TOTALINVESTMENTCOST &&
                      filterMasterData.TOTALINVESTMENTCOST.min !== null &&
                      filterMasterData.TOTALINVESTMENTCOST.max !== null ? (
                        <RangeSlider
                          value={costRange}
                          max={filterMasterData.TOTALINVESTMENTCOST.max}
                          onChange={updateCostRange}
                        />
                      ) : null}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel
                    expanded={filterSectionExpand === 'yearRange'}
                    onChange={handleExpansionClick('yearRange')}
                    className={styleClasses.panel}
                  >
                    <ExpansionPanelSummary
                      className={styleClasses.summary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={styleClasses.heading}>
                        {t('year')}
                        {isYearRangeUpdated ? (
                          <ClearFilterSection
                            handleClick={() => {
                              setYearRangeUpdated(false);
                              setYearRange([minYear, maxCurrentYear]);
                              setClearFilterSection(true);
                            }}
                          />
                        ) : (
                          ''
                        )}
                        {yearRangeApplied ? (
                          <ApplyFilterSection
                            handleClick={filterClick('filter')}
                            updateElement={setYearRangeApplied}
                          />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      className={styleClasses.costContents}
                    >
                      <div>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          css={costRangeInput}
                        >
                          <InputLabel htmlFor="YearMin">Min</InputLabel>
                          <OutlinedInput
                            id="YearMin"
                            value={yearRange[0]}
                            onFocus={e => e.target.select()}
                            onChange={e => {
                              setYearRangeUpdated(true);
                              setYearRangeApplied(true);
                              setYearRange([
                                parseInt(e.target.value) || 0,
                                yearRange[1],
                              ]);
                            }}
                            labelWidth={30}
                          />
                        </FormControl>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          css={costRangeInput}
                        >
                          <InputLabel htmlFor="YearMax">Max</InputLabel>
                          <OutlinedInput
                            id="YearMax"
                            value={yearRange[1]}
                            onFocus={e => e.target.select()}
                            onChange={e => {
                              setYearRangeUpdated(true);
                              setYearRangeApplied(true);
                              setYearRange([
                                yearRange[0],
                                parseInt(e.target.value) || 0,
                              ]);
                            }}
                            labelWidth={40}
                          />
                        </FormControl>
                      </div>

                      <YearSlider
                        value={yearRange}
                        max={maxCurrentYear}
                        min={minYear}
                        onChange={updateYearRange}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
                <div className="filter-actions">
                  <button
                    onClick={filterClick('filter')}
                    className="submit-button"
                    disabled={
                      !countries.length &&
                      !sectors.length &&
                      !sdg.length &&
                      !investmentTypes.length &&
                      searchString.length < 3 &&
                      !isCostRangeUpdated &&
                      !source.length
                    }
                  >
                    {t('Submit')}
                  </button>
                  {
                    {
                      false: null,
                      true: (
                        <button
                          onClick={filterClick('clear')}
                          className="submit-button submit-clear"
                        >
                          {t('Clear')}
                        </button>
                      ),
                    }[location.href && location.href.indexOf('?') > 0]
                  }
                </div>
              </>
            ) : (
              <>
                <Button color="inherit" onClick={() => setOpenDialog(true)}>
                  <FilterList />
                  <FilterHeading>{t('Filter')}</FilterHeading>
                </Button>
                <Dialog
                  fullScreen
                  open={openDialog}
                  onClose={handleDialogClose}
                  TransitionComponent={Transition}
                  css={MobileFilter}
                >
                  <AppBar className={styleClasses.appBar}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography variant="h6" className={styleClasses.title}>
                        {t('Filter')}
                      </Typography>
                      <Button
                        color="inherit"
                        onClick={filterClick('filter')}
                        disabled={
                          !countries.length &&
                          !sectors.length &&
                          !sdg.length &&
                          !investmentTypes.length &&
                          searchString.length < 3 &&
                          !isCostRangeUpdated
                        }
                      >
                        {t('Submit')}
                      </Button>
                    </Toolbar>
                  </AppBar>
                  <div className={styleClasses.root}>
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={filterTab}
                      onChange={handleFilterTabChange}
                      aria-label="Vertical tabs example"
                      className={styleClasses.tabs}
                    >
                      <Tab
                        className={styleClasses.tabItem}
                        label={t('source')}
                        {...filterTabs(0)}
                      />
                      <Tab
                        className={styleClasses.tabItem}
                        label={t('countries')}
                        {...filterTabs(1)}
                      />
                      <Tab
                        className={styleClasses.tabItem}
                        label={t('sectors')}
                        {...filterTabs(2)}
                      />
                      <Tab
                        className={styleClasses.tabItem}
                        label={t('investment_types')}
                        {...filterTabs(3)}
                      />
                      <Tab
                        className={styleClasses.tabItem}
                        label={t('SDG')}
                        {...filterTabs(4)}
                      />
                      <Tab
                        className={styleClasses.tabItem}
                        label={t('Total Investment Cost')}
                        {...filterTabs(5)}
                      />
                    </Tabs>

                    <TabPanel
                      value={filterTab}
                      index={0}
                      className={styleClasses.tabsPanel}
                    >
                      <div className={styleClasses.contentsMobile}>
                        <List>
                          {filterMasterData &&
                            (filterMasterData.SOURCE || []).map(value => {
                              return (
                                <ListItem key={value.campaignSourceID}>
                                  <Checkbox
                                    color="primary"
                                    className={styleClasses.checkbox}
                                    edge="start"
                                    checked={
                                      source.indexOf(value.campaignSourceID) !==
                                      -1
                                    }
                                    onChange={handleSourceChange(
                                      value.campaignSourceID
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    value={value.campaignSourceID}
                                  ></Checkbox>
                                  <p css={filterItem}>
                                    {value.campaignSourceName}
                                  </p>
                                </ListItem>
                              );
                            })}
                        </List>
                      </div>
                    </TabPanel>
                    <TabPanel
                      value={filterTab}
                      index={1}
                      className={styleClasses.tabsPanel}
                    >
                      <div className={styleClasses.contentsMobile}>
                        <List>
                          {filterMasterData &&
                            filterMasterData.COUNTRY &&
                            filterMasterData.COUNTRY.map(value => {
                              return (
                                <ListItem key={value.countryID}>
                                  <Checkbox
                                    color="primary"
                                    className={styleClasses.checkbox}
                                    edge="start"
                                    checked={
                                      countries.indexOf(value.countryID) !== -1
                                    }
                                    onChange={handleCountriesChange(
                                      value.countryID
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    value={value.countryID}
                                  ></Checkbox>
                                  <p>{value.countryName}</p>
                                </ListItem>
                              );
                            })}
                        </List>
                      </div>
                    </TabPanel>
                    <TabPanel value={filterTab} index={2}>
                      <div className={styleClasses.contentsMobile}>
                        <List>
                          {filterMasterData &&
                            filterMasterData.SECTOR &&
                            filterMasterData.SECTOR.map(value => {
                              return (
                                <ListItem key={value.companySectorCodeID}>
                                  <Checkbox
                                    color="primary"
                                    className={styleClasses.checkbox}
                                    edge="start"
                                    checked={
                                      sectors.indexOf(
                                        value.companySectorCodeID
                                      ) !== -1
                                    }
                                    onChange={handleSectorsChange(
                                      value.companySectorCodeID
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    value={value.companySectorCodeID}
                                  ></Checkbox>
                                  <p>{value.description}</p>
                                </ListItem>
                              );
                            })}
                        </List>
                      </div>
                    </TabPanel>
                    <TabPanel value={filterTab} index={3}>
                      <div className={styleClasses.contentsMobile}>
                        <List>
                          {filterMasterData &&
                            (filterMasterData.PROJECTOPPORTUNITYTYPE || []).map(
                              value => {
                                return (
                                  <ListItem
                                    key={value.projectOpportunityTypeID}
                                  >
                                    <Checkbox
                                      color="primary"
                                      className={styleClasses.checkbox}
                                      edge="start"
                                      checked={
                                        investmentTypes.indexOf(
                                          value.projectOpportunityTypeID
                                        ) !== -1
                                      }
                                      onChange={handleInvestmentTypesChange(
                                        value.projectOpportunityTypeID
                                      )}
                                      tabIndex={-1}
                                      disableRipple
                                      value={value.projectOpportunityTypeID}
                                    ></Checkbox>
                                    <p>{value.projectOpportunityTypeName}</p>
                                  </ListItem>
                                );
                              }
                            )}
                        </List>
                      </div>
                    </TabPanel>
                    <TabPanel value={filterTab} index={4}>
                      {filterMasterData && filterMasterData.SDG && (
                        <SDGImageFilter
                          SDGData={filterMasterData.SDG}
                          sdg={sdg}
                          handleSDGChange={handleSDGChange}
                        />
                      )}
                    </TabPanel>
                    <TabPanel value={filterTab} index={5}>
                      <div>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          css={costRangeInputMobile}
                        >
                          <InputLabel htmlFor="CostMin">Min</InputLabel>
                          <OutlinedInput
                            id="CostMin"
                            value={costRange[0].toLocaleString()}
                            onFocus={e => e.target.select()}
                            onChange={e =>
                              setCostRange([
                                parseInt(e.target.value.replace(/\D/g, '')) ||
                                  0,
                                costRange[1],
                              ])
                            }
                            endAdornment={
                              <InputAdornment position="end">$</InputAdornment>
                            }
                            labelWidth={30}
                          />
                        </FormControl>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          css={costRangeInputMobile}
                        >
                          <InputLabel htmlFor="CostMax">Max</InputLabel>
                          <OutlinedInput
                            id="CostMax"
                            value={costRange[1].toLocaleString()}
                            onFocus={e => e.target.select()}
                            onChange={e =>
                              setCostRange([
                                costRange[0],
                                parseInt(e.target.value.replace(/\D/g, '')) ||
                                  0,
                              ])
                            }
                            endAdornment={
                              <InputAdornment position="end">$</InputAdornment>
                            }
                            labelWidth={30}
                          />
                        </FormControl>

                        {filterMasterData &&
                        filterMasterData.TOTALINVESTMENTCOST &&
                        filterMasterData.TOTALINVESTMENTCOST.min !== null &&
                        filterMasterData.TOTALINVESTMENTCOST.max !== null ? (
                          <RangeSlider
                            value={costRange}
                            max="2021"
                            onChange={updateCostRange}
                          />
                        ) : null}
                      </div>
                    </TabPanel>
                  </div>
                </Dialog>
                {
                  {
                    false: null,
                    true: (
                      <button
                        onClick={filterClick('clear')}
                        css={[buttonSubmit, buttonClear]}
                        color="inherit"
                      >
                        {t('Clear')}
                      </button>
                    ),
                  }[location.href && location.href.indexOf('?') > 0]
                }
              </>
            )}
          </GridItem>

          <GridItem md={8}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={10}>
                <Filter>
                  {
                    {
                      true: null,
                      false:
                        projectsCount > 0 ||
                        Object.keys(searchCriteria).length > 0 ? (
                          <FilterText
                            filterMasterData={filterMasterData}
                            searchCriteria={searchCriteria}
                            totalProjectCount={projectsCount}
                            currentProjectCount={filteredProjects.length}
                            location={location}
                            navigate={navigate}
                            filterClick={filterClick('clear')}
                            placeholderSingle="Project"
                            placeholder="Projects"
                          />
                        ) : (
                          ''
                        ),
                    }[isProcessing]
                  }
                </Filter>
              </GridItem>

              <GridContainer css={statisticsContainer}>
                {projectschartCount > 0 ? (
                  <GridContainer>
                    {investmentTypeData ? (
                      <GridItem md={12} css={ChartsTopGrid}>
                        <SectorGridTop>
                          <HeadingTop>
                            <h4>{t('statistics_graph_1')}</h4>
                          </HeadingTop>
                          {investmentTypeData.length > 0 &&
                          projectschartCount > 0 ? (
                            <InvestmentBarChartCustomShape
                              investmentTypeData={investmentTypeData}
                              countryID={countryID}
                              programUrl={pageContext.programUrl}
                            />
                          ) : (
                            <div style={noContentsStyle}>
                              {isLoading ? (
                                <p style={fontStyle}>{t('Loading...')}</p>
                              ) : (
                                <p style={fontStyle}>
                                  {t('No Projects to display')}
                                </p>
                              )}
                            </div>
                          )}
                        </SectorGridTop>
                      </GridItem>
                    ) : (
                      ''
                    )}

                    {sectorData ? (
                      <GridItem md={12} css={ChartsTopGrid}>
                        <SectorGridTop>
                          <HeadingTop>
                            <h4>{t('statistics_graph_2')}</h4>
                          </HeadingTop>
                          {sectorData ? (
                            <SectorsPieChartWithPaddingAngle
                              sectorData={sectorData}
                              countryID={countryID}
                              programUrl={pageContext.programUrl}
                            />
                          ) : (
                            <div style={noContentsStyle}>
                              {isLoading ? (
                                <p style={fontStyle}>{t('Loading...')}</p>
                              ) : (
                                <p style={fontStyle}>
                                  {t('No Projects to display')}
                                </p>
                              )}
                            </div>
                          )}
                        </SectorGridTop>
                      </GridItem>
                    ) : (
                      ''
                    )}
                  </GridContainer>
                ) : (
                  <GridContainer>
                    <GridItem>
                      <Container width="100%">
                        <div className="three-dots-loader">
                          <ThreeDots
                            color="#5990ff"
                            height={100}
                            width={100}
                            timeout={10000}
                          />
                        </div>
                      </Container>
                    </GridItem>
                  </GridContainer>
                )}

                <GridItem xs={12} sm={12} md={12} css={TopGrid}>
                  <SectorGridTop>
                    <HeadingTop>
                      <h4>{t('statistics_graph_3')}</h4>
                    </HeadingTop>
                    <ProjectCountByCountry
                      countryID={countryID}
                      programUrl={pageContext.programUrl}
                    />
                  </SectorGridTop>
                </GridItem>

                {surveyCountriesData ? (
                  <GridItem xs={12} sm={12} md={12} css={TopGrid}>
                    <SectorGridTop>
                      <HeadingTop>
                        <h4>{t('statistics_graph_4')}</h4>
                      </HeadingTop>
                      <div css={legendscss}>
                        <ResponsiveContainer height={400} width={'100%'}>
                          <BarChart
                            layout="vertical"
                            data={surveyCountriesData}
                            margin={{
                              top: 20,
                              right: 20,
                              bottom: 20,
                              left: 50,
                            }}
                            stackOffset="expand"
                          >
                            <XAxis hide type="number" stroke="black" />
                            <YAxis
                              type="category"
                              dataKey="name"
                              stroke="black"
                              fontSize="12"
                              styles="font-family: Open Sans,-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;"
                            />
                            <Tooltip />
                            <Bar
                              dataKey="existing_Investor"
                              fill="#00bcd4"
                              stackId="a"
                              barSize={25}
                            >
                              <LabelList
                                dataKey="existing_Investor"
                                position="center"
                                content={renderCustomizedLabel}
                              />
                            </Bar>
                            <Bar
                              dataKey="external_advisor"
                              fill="#9131eb"
                              stackId="a"
                              barSize={25}
                            >
                              <LabelList
                                dataKey="external_advisor"
                                position="center"
                                content={renderCustomizedLabel}
                              />
                            </Bar>
                            <Bar
                              dataKey="diaspora"
                              fill="#34cc34"
                              stackId="a"
                              barSize={25}
                            >
                              <LabelList
                                dataKey="diaspora"
                                content={renderCustomizedLabel}
                              />
                            </Bar>
                            <Bar
                              dataKey="parentCompanyChannel"
                              fill="#82ba7f"
                              stackId="a"
                              barSize={25}
                            >
                              <LabelList
                                dataKey="parentCompanyChannel"
                                position="center"
                                content={renderCustomizedLabel}
                              />
                            </Bar>
                            <Bar
                              dataKey="embassy"
                              fill="#f5d008"
                              stackId="a"
                              barSize={25}
                            >
                              <LabelList
                                dataKey="embassy"
                                position="center"
                                content={renderCustomizedLabel}
                              />
                            </Bar>
                            <Bar
                              dataKey="directContactWithIPA"
                              fill="#ffa602"
                              stackId="a"
                              barSize={25}
                            >
                              <LabelList
                                dataKey="directContactWithIPA"
                                position="center"
                                content={renderCustomizedLabel}
                              />
                            </Bar>
                            <Bar
                              dataKey="privateSectorMediaAndOther"
                              fill="#a15555"
                              stackId="a"
                              barSize={25}
                            >
                              <LabelList
                                dataKey="privateSectorMediaAndOther"
                                position="center"
                                content={renderCustomizedLabel}
                              />
                            </Bar>
                            <Legend />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </SectorGridTop>
                  </GridItem>
                ) : (
                  ''
                )}

                <GridItem xs={12} sm={12} md={12} css={TopGrid}>
                  <SectorGridTop>
                    <HeadingTop>
                      <h4>{t('statistics_graph_5')}</h4>
                    </HeadingTop>
                    <div css={legendscss}>
                      <ResponsiveContainer height={750} width={'100%'}>
                        <ComposedChart
                          layout="vertical"
                          data={ipaCountryData}
                          margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 50,
                          }}
                        >
                          <CartesianGrid
                            stroke="#efefef"
                            strokeDasharray="3 3"
                          />
                          <XAxis type="number" />
                          <YAxis dataKey="name" type="category" />
                          <Tooltip />
                          <Legend />
                          <Bar
                            dataKey="quality_ratio"
                            barSize={25}
                            fill="#b39de1"
                          >
                            <LabelList
                              dataKey="quality_ratio"
                              position="end"
                              content={renderCustomizedLabel}
                            />
                          </Bar>
                        </ComposedChart>
                      </ResponsiveContainer>
                    </div>
                  </SectorGridTop>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} css={TopGrid}>
                  <SectorGridTop width="70%">
                    <HeadingTop>
                      <h4>{t('statistics_graph_6')}</h4>
                    </HeadingTop>
                    <div width={300} css={linearcss}>
                      <ResponsiveContainer height={450} width={'100%'}>
                        <PieChart>
                          <Pie
                            data={pieChartData}
                            labelLine={true}
                            label
                            outerRadius={180}
                            fill="#8884d8"
                          >
                            {// eslint-disable-next-line react/jsx-key
                            pieChartData.map((entry, index) => (
                              <Cell
                                key={index}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </SectorGridTop>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} css={TopGrid}>
                  <SectorGridTop>
                    <HeadingTop>
                      <h4>{t('statistics_graph_7')}</h4>
                    </HeadingTop>
                    <div css={legendscss}>
                      <ResponsiveContainer
                        height={530}
                        width={'100%'}
                        styles="margin-top: 1em;"
                      >
                        <BarChart
                          layout="vertical"
                          data={investorData}
                          stackOffset="sign"
                          margin={{ top: 30, right: 30, left: 40, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis hide type="number" dataKey="Importance" />
                          <YAxis dataKey="name" type="category" />
                          <Tooltip />
                          <Legend />
                          <ReferenceLine x={4} stroke="#000" />
                          <Bar
                            dataKey="Quality"
                            fill="#8884d8"
                            barSize={25}
                            stackId="stack"
                          />
                          <Bar
                            dataKey="Importance"
                            fill="#82ca9d"
                            barSize={25}
                            stackId="stack"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </SectorGridTop>
                </GridItem>

                <GridItem>
                  <SectorGridTop>
                    <HeadingTop>
                      <h4>{t('statistics_graph_7')}</h4>
                    </HeadingTop>
                    <div css={legendscss}>
                      <ResponsiveContainer
                        height={400}
                        width={'100%'}
                        styles="margin-top: 1em;"
                      >
                        <LineChart width={600} height={300} data={linearData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="name"
                            padding={{ left: 30, right: 30 }}
                          />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="projectCount"
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </SectorGridTop>
                </GridItem>

                <GridItem>
                  <SectorGridTop>
                    <HeadingTop>
                      <h4>{t('statistics_graph_7')}</h4>
                    </HeadingTop>
                    <div css={legendscss}>
                      <ResponsiveContainer
                        height={400}
                        width={'100%'}
                        styles="margin-top: 1em;"
                      >
                        <BarChart
                          data={investorUpdateData}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar
                            dataKey="Quality"
                            fill="#8884d8"
                            background={{ fill: '#eee' }}
                          />
                          <Bar dataKey="Importance" fill="#82ca9d" />
                          <Brush dataKey="name" height={30} stroke="#8884d8" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </SectorGridTop>
                </GridItem>
              </GridContainer>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </Layout>
  );
};

export default withStyles(dashboardStyle)(Projects);

const ClearFilterSection = ({ handleClick }) => {
  return (
    <FormControlLabel
      aria-label="Acknowledge"
      css={clearSection}
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
      control={
        <IconButton
          size="small"
          color="inherit"
          onClick={handleClick}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

const ApplyFilterSection = ({ handleClick, updateElement }) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      aria-label="Acknowledge"
      css={clearSection}
      onClick={() => (updateElement ? updateElement(false) : '')}
      onFocus={event => event.stopPropagation()}
      control={
        <button css={buttonApply} onClick={handleClick}>
          {t('apply')}
        </button>
      }
    />
  );
};

ClearFilterSection.propTypes = {
  handleClick: PropTypes.func,
};

ApplyFilterSection.propTypes = {
  handleClick: PropTypes.func,
  updateElement: PropTypes.func,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

Projects.propTypes = {
  classes: PropTypes.any,
  location: PropTypes.object,
  navigate: PropTypes.func,
  pageContext: PropTypes.any,
};

function filterTabs(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
