import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Chip from '@material-ui/core/Chip';
import styles from '../styles/filterText';
import { unset } from 'lodash';

const { filterChip } = styles;

const FilterText = ({
  filterMasterData,
  partnerList,
  searchCriteria,
  totalProjectCount,
  currentProjectCount,
  location,
  navigate,
  filterClick,
  placeholderSingle,
  placeholder,
}) => {
  const { t } = useTranslation();
  const searchKeys = Object.keys(searchCriteria || {});
  const totalElement =
    totalProjectCount === currentProjectCount
      ? `${totalProjectCount} ${
          totalProjectCount === 1 ? t(placeholderSingle) : t(placeholder)
        }`
      : `${t('Showing')} ${currentProjectCount} ${t(
          'out of'
        )} ${totalProjectCount} ${t(placeholder)}`;
  const filters = {
    Country: {
      title: t('countries'),
      filterKey: 'COUNTRY',
      selector: item => item && { id: item.countryID, value: item.countryName },
      finder: (collection, value) => collection.countryID === parseInt(value),
    },
    Partner: {
      title: t('partner'),
      filterKey: 'PARTNERS',
      selector: item =>
        item && { id: item.institutionID, value: item.institutionName },
      finder: (collection, value) =>
        collection.institutionID === parseInt(value),
    },
    TotalInvestmentCost: {
      title: t('total_investment_cost'),
      filterKey: 'TotalInvestmentCost',
      selector: item => item,
      finder: (collection, value) =>
        collection.projectOpportunityTypeID === parseInt(value),
    },
    Year: {
      title: t('year'),
      filterKey: 'Year',
      selector: item => item,
      finder: (collection, value) => collection.year === parseInt(value),
    },
    MediaType: {
      title: t('media_type'),
      filterKey: 'MEDIATYPE',
      selector: item => item && { id: item.value, value: item.name },
      finder: (collection, value) => collection.value === parseInt(value),
    },
    Sector: {
      title: t('sector'),
      filterKey: 'SECTOR',
      selector: item =>
        item && { id: item.companySectorCodeID, value: item.description },
      finder: (collection, value) =>
        collection.companySectorCodeID === parseInt(value),
    },
    InvestmentType: {
      title: t('investment_type'),
      filterKey: 'PROJECTOPPORTUNITYTYPE',
      selector: item =>
        item && {
          id: item.projectOpportunityTypeID,
          value: item.projectOpportunityTypeName,
        },
      finder: (collection, value) =>
        collection.projectOpportunityTypeID === parseInt(value),
    },
    sdg: {
      title: t('SDG'),
      filterKey: 'SDG',
      selector: item =>
        item && {
          id: item.sdgid,
          value: `${t('goal')} ${item.sdgid}: ${item.sdgTitle}`,
        },
      finder: (c, value) => c.sdgid === parseInt(value),
    },
    search: {
      title: t('Search'),
      filterKey: 'Search',
      selector: item => item,
      finder: (c, value) => c.sdgid === parseInt(value),
    },
    Favorites: {
      title: t('Favorites'),
      filterKey: 'Favorites',
      selector: item => item,
      finder: (c, value) => value,
    },
    Source: {
      title: t('Source'),
      filterKey: 'SOURCE',
      selector: item =>
        item && { id: item.campaignSourceID, value: item.campaignSourceName },
      finder: (c, value) => c.campaignSourceID === parseInt(value),
    },
    SourceEntityType: {
      title: t('source_entity_type'),
      filterKey: 'SOURCEENTITYTYPE',
      selector: item =>
        item && {
          id: item.sourceEntityTypeID,
          value: item.sourceEntityTypeName,
        },
      finder: (c, value) => c.sourceEntityTypeID === parseInt(value),
    },
    EFSDType: {
      title: t('alignment_efsd_window'),
      filterKey: 'EFSDTYPE',
      selector: item =>
        item && { id: item.efsdTypeID, value: item.efsdTypeName },
      finder: (collection, value) => collection.efsdTypeID === parseInt(value),
    },
    Promotion: {
      title: t('special_initiatives'),
      filterKey: 'PROMOTION',
      selector: item => item && { id: item.value, value: item.name },
      finder: (c, value) => c.value === parseInt(value),
    },
    partnerType: {
      title: t('type'),
      filterKey: 'PARTNERTYPE',
      selector: item =>
        item && {
          id: item.partnerTypeID,
          value: item.partnerTypeName,
        },
      finder: (collection, value) =>
        collection.partnerTypeID === parseInt(value),
    },
    wwh: {
      title: t('wwh'),
      filterKey: 'WWH',
      selector: item => item && { id: item.value, value: item.name },
      finder: (c, value) => c.value === parseInt(value),
    },
  };

  if (searchKeys.length <= 0) {
    return <h5>{totalElement}</h5>;
  }

  const handleDelete = (keys, value) => {
    if (keys === 'Favorites') {
      navigate(`${location.pathname}`, {});
    } else {
      const index = searchCriteria[keys.searchKey].indexOf(String(value.id));
      searchCriteria[keys.searchKey].splice(index, 1);

      Object.keys(searchCriteria).forEach(key => {
        if (
          !searchCriteria[key] ||
          searchCriteria[key].length === 0 ||
          (keys.searchKey === 'TotalInvestmentCost' &&
            keys.searchKey === key) ||
          (keys.searchKey === 'Year' && keys.searchKey === key)
        ) {
          delete searchCriteria[key];
        }
      });

      let querySC = searchCriteria;
      if (keys.searchKey === 'Country') {
        unset(querySC, 'Partner');
      }

      filterClick('clear');
      const queryString = getQueryString(querySC);
      navigate(
        `${location.pathname}${queryString ? '?' + queryString : queryString}`,
        {}
      );
    }
  };

  const getFilterSearchKeys = title => {
    const searchKey = Object.keys(filters).find(
      key => filters[key].title === title
    );
    const filterKey = filters[searchKey].filterKey;

    return { searchKey, filterKey };
  };

  const getQueryString = params => {
    const queryString = Object.keys(params)
      .map(key =>
        key === 'search'
          ? `${key}=${params[key]}`
          : `${key}=${params[key].reduce((acc, value) => {
              return acc === '' ? `${acc}${value}` : `${acc}::${value}`;
            }, '')}`
      )
      .join('&');

    return queryString;
  };

  let filterLabels = searchKeys
    .map(searchKey => ({ searchKey, filter: filters[searchKey] }))
    .map(({ searchKey, filter }) => ({
      [filter.title]:
        searchKey === 'TotalInvestmentCost' ||
        searchKey === 'Year' ||
        searchKey === 'search' ||
        searchKey === 'WWH'
          ? searchCriteria[searchKey]
          : [
              ...(searchCriteria[searchKey] || []).map(value =>
                ((
                  ({ ...filterMasterData, PARTNERS: partnerList } || {})[
                    filter.filterKey
                  ] || []
                ).find(master => filter.finder(master, value)))
              ),
            ].map(filter.selector),
    }))
    .reduce((filterElements, filter) => {
      const [entries] = [...(Object.entries(filter) || [])];
      const [name, values] = [...(entries || [])];
      const filterValues =
        name === t('total_investment_cost') ? (
          [
            values && (
              <span key={name}>
                <Chip
                  size="small"
                  label={values
                    .map(val => `${parseInt(val).toLocaleString()}€`)
                    .join(' - ')}
                  onDelete={() =>
                    handleDelete(getFilterSearchKeys(name), values)
                  }
                  css={filterChip}
                />
              </span>
            ),
          ]
        ) : name === 'Favorites' ? (
          <span key="Favorites">
            {' '}
            <Chip
              size="small"
              label="Favorites"
              onDelete={() => handleDelete('Favorites')}
              css={filterChip}
            />
          </span>
        ) : name === t('wwh') ? (
          [
            values && (
              <span key="WWH">
            {' '}
            <Chip
              size="small"
              label={t('wwh')}
              onDelete={() => handleDelete(getFilterSearchKeys(name), values)}
              css={filterChip}
            />
            </span>
            )
          ]
        ): name === t('year') ? (
          [
            values && (
              <span key={name}>
                <Chip
                  size="small"
                  label={values.map(val => `${val}`).join(' - ')}
                  onDelete={() =>
                    handleDelete(getFilterSearchKeys(name), values)
                  }
                  css={filterChip}
                />
              </span>
            ),
          ]
        ) : (
          (values || []).reduce(
            (valAcc, value) => [
              ...valAcc,
              value && (
                <span key={name === 'Search' ? value : value.value}>
                  {' '}
                  <Chip
                    size="small"
                    label={name === 'Search' ? value : value.value}
                    onDelete={() =>
                      handleDelete(getFilterSearchKeys(name), value)
                    }
                    css={filterChip}
                  />
                </span>
              ),
            ],
            []
          )
        );
      const exception = ['Favorites',t('wwh')];
      const filterElement = [
        !exception.includes(name) && (
          <label key={name}>{name.toUpperCase()}: </label>
        ),
        filterValues,
      ];

      return filterElements.length === 0
        ? [...filterElements, filterElement]
        : [...filterElements, <br key={name} />, filterElement];
    }, []);

  return (
    <h5>
      {totalElement}
      {filterLabels && filterLabels.length > 0 ? t(' for ') : ''}
      <span>{filterLabels}</span>
    </h5>
  );
};

FilterText.propTypes = {
  filterMasterData: PropTypes.object,
  searchCriteria: PropTypes.object,
  totalProjectCount: PropTypes.number,
  currentProjectCount: PropTypes.number,
  location: PropTypes.object,
  navigate: PropTypes.func,
  filterClick: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderSingle: PropTypes.string,
};

export default FilterText;
