import 'rc-slider/assets/index.css';
import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import styles from '../styles/projects';

const { costSlider } = styles;

const YearSlider = ({ value, max,min,onChange }) => {

  return (
    <div>
      <Slider css={costSlider} value={value} max={max} onChange={onChange} min={min} step={1} />
    </div>
  );
};

YearSlider.propTypes = {
  max: PropTypes.number,
  min:PropTypes.min,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default YearSlider;
