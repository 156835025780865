import { css } from '@emotion/react';

const filterChip = css`
  background-color: #4a91e1 !important;
  max-width: 195px;
  font-size: 13px !important;
  margin-left: 8px;
`;

const filterText = css`
  line-height: 1.8rem;
  .space {
    width: 20px;
  }
  label {
    font-size: 14px;
  }
`;

export default {
  filterChip,
  filterText,
};
